.tn-account-login-page {
    #tn-login-form {
        width:100%;
        @media (min-width: $screen-xs-max) {
            width:80%;
        }
    }
    .col-sm-6 {
        @media (min-width: $screen-xs-max) {
            width:50%;
        }
    }
}
