
.max-width {
    @media (min-width: $screen-xs-max) {
        width: 80%;
    }
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
.global-header {
    background:#ffffff;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    padding:0;
    z-index:9999;
    @media (max-width: $screen-xs-max) {
        position:fixed;
        left:0;
        right:0;
        top:0;
    }
    .brand-logo-link {
        display:inline-block;
        margin:10px;
        @media (min-width: $screen-xs-max) {
            margin:20px;
        }
        img {
            @media (max-width: $screen-xs-max) {
                width:40px;
            }
        }
        .notext-logo {
            @media (min-width: $screen-xs-max) {
                display:none;
            }
        }
        .main-logo {
            @media (max-width: $screen-xs-max) {
                display:none;
            }
        }
    }
}
.page-wrap {
    background-color: $page-background;
    @media (max-width: $screen-xs-max) {
        margin-top:60px;
    }
}
.content-wrap {
    background: $light-bg;
    padding:0 0 50px 0;
    min-height: 90vh;
    @media (min-width: $screen-xs-max) {
        padding: 0;
    }
}
// inset style used throughout

@mixin inset() {
    background: rgba($white, 0.8);
    border: 1px solid  rgba(0,0,0,0.09);
    padding: 20px;
    border-radius: 4px;
}

.tn-events-filter-component,
.tn-listing-component,
.tn-calendar-component,
.tn-cart-component,
.tn-listing-legend-container {
    @include inset();
}

// subnav bar
.tn-subnav-component {
    background: #377AA4;
    padding:1% 3%;
    position:relative;
    margin:0 auto !important;
    a {
        color:$white;
    }
}
.tn-subnav-component .tn-login-link  {
    .tn-link {
        display: inline-block;   
    }
    .tn-logged-in-text {
        display:none !important;
    }
    .tn-logged-in-email {
        display:inline-block !important;
        @media (max-width: $screen-xs-max) {
            display:none !important;
        }
    }
}


.tn-heading {
    font-size: 200%;
    text-align: left;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 0 0 20px 0;
    @media (max-width: $screen-xs-max) {
        font-size: 140%;
    }
}

// event page head

.tn-detail-page-header {
    padding: 5% 6%;
    border: 1px solid rgba(0,0,0,0.1);
    background: rgba(255,255,255,0.7);
    margin-left: -5%;
}

.tn-event-listing-view__controls-container, .tn-event-listing-view__results-container {
    padding:5%;
    @media (min-width: $screen-xs-max) {
        padding: 1%;
    }
}

.tn-event-listing__primary-views-container {
    position:relative;
}

.tn-cart-component {
    margin-bottom:20px;
}

@media (min-width: $screen-xs-max) {
    .col-sm-6 {
        width:100%;
    }
}
