#site-footer {
    background-color:#b5d99f;
    .footer-content {
        color:#34322f;
        padding:20px;
        text-align:center;
        @media (min-width: $screen-xs-max) {
            padding:20px 80px;
            text-align:left;
        }
        h3 {
            color:$black;
            font-size:1.1em;
            margin-bottom:30px;
        }

        .address {
            display: block;
            font-size:0.8em;

            @media (min-width: $screen-xs-max) {
                display: inline-block;
            }
        }

        .email {
            display: inline-block;
            font-size:0.8em;

            @media (min-width: $screen-xs-max) {
                margin-left:50px;
                vertical-align: top;
            }

            a {
                color:#204760;
                cursor:pointer;
                &:hover {
                    color:#0d2839;
                }
            }
        }
    }
    .sub-footer {
        background-color:#1d1814;
        padding:20px;
        @media (min-width: $screen-xs-max) {
            display:flex;
            padding:20px 80px;
        }
        @media (max-width: $screen-xs-max) {
            text-align:center;
        }
        a {
            color:#ffffff;
            cursor:pointer;
            display:inline-block;
            font-size:0.8em;
            margin-right:20px;
            text-decoration:none;
            &:hover {
                color:#f2f4cd;
                text-decoration:underline;
            }
        }
        .img-container {
            flex: 1 1 auto;
            text-align:right;
            @media (max-width: $screen-xs-max) {
                margin-top:30px;
                text-align:center;
            }
        }
    }
}
