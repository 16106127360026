.tn-cart-details-page {
    .tn-heading-info {
        background:#ACDEF5;
    }
}

.tn-flex-cart {
    position:fixed;
    width: inherit;
    @media (max-height: 950px) {
        position:relative;
    }
}

.tn-cart-buttons__primary-action {
    @media (max-width: $screen-xs-max) {
        padding-left:20px !important;
        padding-right:20px !important;
    }
}

.tn-flex-cart__primary-section--actions, .tn-flex-cart__secondary-section--start-over, .tn-flex-performance__ticket-selector-target {
    .btn {
        @include button-variant(#ffffff, darken($blue, 20%), $blue);
    }
}

.tn-flex-selections__btn-start-over {
    background:none !important;
    border:none;
    color:$blue !important;
    font-size:1.1em;
    padding:0 0 0 30px;
    position:relative;
    &:before {
        content: "\F021";
        font-family: FontAwesome;
        position:absolute;
        left:0px;
        top:0px;
    }
}

.tn-flex-production__btn-toggle-performances {
    &.btn-default {
        background:#E9E9E9;
        border:none;
        color:$blue;
        .tn-toggle-btn__icon {
            color:$blue;
        }
    }
}

.tn-flex-selection--placeholder {
    opacity:0.5;
}

.tn-checkout-survey-component {
    background:#ffffff;
    padding:20px;
    margin:20px 0;
    @media (min-width: $screen-xs-max) {
        margin:40px 0;
    }
    h2 {
        margin-top:0;
    }
    @media (max-width: $screen-xs-max) {
        .form-group {
            .checkbox {
                display:inline-block;
                label{
                    background:#ccc;
                    text-size:0;
                    text-indent:-10000px;
                    width:60px;
                    height:40px;
                    border-radius:30px;
                    -webkit-transition: .4s;
                    transition: .4s;
                    position:relative;
                    &:after {
                        content: "";
                        width:30px;
                        position:absolute;
                        top:5px;
                        left:5px;
                        background:#fff;
                        height:30px;
                        -webkit-transition: .4s;
                        transition: .4s;
                        border-radius:50%;
                    }
                    &.checked {
                        background:green;
                        &:after {
                            -webkit-transform: translateX(20px);
                            -ms-transform: translateX(20px);
                            transform: translateX(20px);
                        }
                    }
                }
                input {
                    display:none;
                }
                .tn-required-field {
                    margin-left:30px;
                    margin-top:2px;
                }
            }
        }
    }
}

.tn-cart-item-detail__list-item--seat-location {
    display:none;
}

.tn-cart-item-summary {
    grid-template-columns: auto !important;
}

.tn-cart-item-summary__property--price-total {
    grid-column-start:4;
    text-align:right;
    padding-right:45px !important;
}

.tn-cart-item-summary__property--date-time {
    font-weight:bold;
    grid-column:1 !important;
}

.tn-cart-line-item-name {
    span {
        color:#333333;
        font-family:"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size:22px;
        font-weight:bold;
    }
}

.tn-cart-item-summary__property--certificate-number, .tn-cart-item-summary__property--actions {
    grid-column:1 !important;
}

.tn-cart-totals__line-item--subtotal, .tn-cart-totals__line-item--total {
    h3, .tn-cart-totals__value, .tn-cart-totals__value--total {
        display:inline-block;
        width:auto;
    }
    .tn-cart-totals__value {
        margin-left:10px;
    }
}

#tn-payment-apply-gift-certificate-form {
    .input-group {
        width:100%;
    }
}
