.dynamic-links, .package-dynamic-links {
    background:#ffffff; 
    margin-bottom:10px;
    padding:20px;
    width:100%;
    h2 {
        margin:0 1% 20px 1%;
        text-align:left;
        @media (max-width: $screen-xs-max) {
            font-size: 1.4em;
            text-align: center;
        }
    }
}


.filtered {
    .tn-event-listing-mode-tab-nav__list {
        display:none;
    }
    .dynamic-links, .package-dynamic-links {
        @media (min-width: $screen-xs-max) and (max-width: $screen-lg-desktop) {
            text-align:center;
            right:0;
            width:100%;
        }
    }
}

.dynamic-links, .package-dynamic-links {
    a.btn-default {
        margin:0 5px;
        @media (max-width: $screen-xs-max) {
            margin:0;
        }
        @media (max-width: $screen-md) {
            display:block;
            font-size:1em;
            font-weight:bold;
            width:100%;
            margin-bottom:10px;
        }
        @media (min-width: $screen-xs-max) {
            margin:0 1%;
            width:31%;
        }
        &.active {
            color:#2c4f65;
            background:#FFEB59;
            border:1px solid #214962;
        }
        &:hover {
            background:#ffe000;
            color:#2c4f65;
        }
    }
}
