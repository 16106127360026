.tn-detail-page-header {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    .tn-detail-title {
        font-size: 250%;
        padding: 0;
    }
    .tn-detail-image {
        display: none;
    }
}
.tn-detail {
    order: 1;
    .tn-detail-selected-date {
        font-size: 160%;
        display: block;
        float: none;
    }
    .tn-detail-performance-title { // repeated, hide
        display: none;
    }
}
.tn-detail-date-list {
    order: 2;
}

.tn-event-detail__display-time {
    font-size:1.5em !important;
    font-weight:bold;
}

.tn-additional-events__btn-toggle-list-view {
    background:transparent;
    border:1px solid $blue;
    color:$blue;
    &:hover {
        background:transparent;
        border:1px solid $blue;
        color:$blue;
        .tn-toggle-btn__icon {
            background:$blue;
            color:$white;
        }
    }
}

.tn-detail-production-text-toggle-link {
    background: #e9e9e9;
    border: none;
    color: #326F95;
    padding-right:40px;
    position:relative;
    &.collapsed {
        &:after {
            content: "\E114";
        }
    }
    &:after {
        border-left:1px solid rgba(0,0,0,.2);
        bottom:0;
        content: "\E113";
        font-family: Glyphicons Halflings;
        padding-top:5px;
        position:absolute;
        right:0;
        top:0;
        width:30px;
    }
}
