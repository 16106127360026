/*------------------------------------*\
    Hamburger Menu Icon & Animation
\*------------------------------------*/

.c-hamburger {
    width: 28px;
    height: 18px;
    position: relative;
    z-index: 3000;
    margin: 0px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  .c-hamburger:hover span {
        background: #999;
    }
  
  .c-hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #333333;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  .c-hamburger span:nth-child(1) {
    top: 0px;
  }
  
  .c-hamburger span:nth-child(2), .c-hamburger span:nth-child(3) {
    top: 6px;
  }
  
  .c-hamburger span:nth-child(4) {
    top: 12px;
  }
  
  .state--nav-open .c-global-nav-toggle__label {
    opacity: 0;
  }
  .state--nav-open .c-hamburger span:nth-child(1) {
    top: 6px;
    width: 0%;
    left: 50%;
  }
  
  .state--nav-open .c-hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .state--nav-open .c-hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .state--nav-open .c-hamburger span:nth-child(4) {
    top: 6px;
    width: 0%;
    left: 50%;
  }
  