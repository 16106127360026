.tn-prod-listing {
    a {
        color: $text-color;
    }
}
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}
.tn-events-filter-component {
    font-size: 80%;
    .row:first-of-type {
        border-bottom: 1px solid darken($light-bg, 10%);
    }
    h3 {
        font-size: 100%;
    }
}
.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}

.tn-events-listing-page {
    padding:1% 3%;
    .tn-events-calendar__day-number {
        text-align: left;
    }
    .tn-events-calendar__table .tn-events-calendar__event:active {
        background-color: darken($brand-primary, 20%);
    }
    .tn-event-listing-mode-tab-nav__list-item {
        color: $black;
        font-weight:bold;
        @media (max-width: $screen-xs-max) {
            font-size:75%;
            &.active {
                font-size:75% !important;
            }
        }
    }
    .tn-btn-datepicker__icon-container {
        color: $blue;
    }
    .tn-datepicker td.day[aria-current=date] span {
        color: #fff;
        background-color: $brand-primary;
        border-color: $brand-primary;
    }
    .tn-btn-datepicker__btn {
        background:transparent;
        border:1px solid $blue;
        color:$blue;
        font-weight:bold;
    }
    &.filtered {
        .tn-event-listing-view__controls-container {
            display:none;
        }
    }
}

.tn-event-listing-mode-tab-nav__list {
    justify-content:left !important;
    position:relative;
    z-index:555;
    margin:20px 0 0 0 !important;
}
