main {
    padding:1% 3%;
} 

main.page-wrap {
    overflow:hidden;
    padding:0;
}

.tn-events-detail-page, .tn-flex-package-detail-page {
    @media (min-width: $screen-lg) {
        padding:2%;
    }
    ul.tn-ticket-selector__pricetype-list {
        @media (min-width: $screen-xs-max) {
            width:60%;
        }
    }
}

p, li {
    a {
        color:#2e6688;
        text-decoration:underline;
        &:hover {
            color:#214962;
            text-decoration:underline;
        }
    }
}

// Donations

.tn-contribution-type-form-container {
    .radio {
        @media (max-width: $screen-xs-max) {
            display:inline-block;
            width:35%;
        }
    }
}

.tn-header-component {
    position:relative;
}

.hide-info {
    background:transparent;
    margin-bottom:10px;
    position:absolute;
    top:0;
    right:0;
    &:hover {
        background:transparent;
        color:#254b61;
    }
    &:after {
        border-left:none !important;
    }
}


.tn-package-list-no-packages-available-message {
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
    h2 {
        margin-top:0;
    }
}
