.tn-package-list {
    margin-top:5px !important;
}

.tn-package-listing-page {
    .tn-heading-sub-text {
        text-align: left;
    }
    .tn-ticket-selector__pricetype-name {
        font-size:0.8em !important;
    }
}

.tn-flex-package-details__details-and-image-container {
    flex-wrap:wrap;
    .tn-flex-package-details__image-wrapper {
        width:100% !important;
        .tn-flex-package-details__image {
            margin-bottom:20px;
            width:100%;
        }
    }
}

.tn-ticket-selector {
    max-width:100% !important;
}

.tn-flex-package-details__title {
    font-size:1.5em !important;
    @media (min-width: $screen-xs-max) {
        font-size:2em !important;
    }
}

.tn-flex-package-details__date-range, .tn-package-list-item__date-range {
    font-size:1.2em !important;
    font-weight:700;
    margin-bottom:20px;
    @media (min-width: $screen-xs-max) {
        font-size:1.5em;
    }
}

.tn-prod-list-item--package {
    display:flex;
    flex-direction:column;
    h3 {
        order:0;
    }
    .tn-prod-list-item__property--img-container {
        order:1;
    }
    .tn-package-list-item__details {
        order:2;
    }
    .tn-prod-list-item__property--heading {
        a {
            text-decoration:none;
            &:hover {
                text-decoration:underline;
            }
        }
    }
}

.tn-flex-performance-group__selection-status {
    color:#ad413d !important;
}

.tn-flex-performance-group {
    border:2px solid #CBC9C9 !important;
    .tn-flex-performance-group__title {
        background-color:transparent !important;

        @media (min-width: $screen-xs-max) {
            font-size:1.6em !important;
        }
    }
    .tn-flex-performance-group__btn-toggle-productions {
        background:none !important;
        border:none !important;
        color:$blue !important;
        .tn-toggle-btn__icon {
            border-left:none !important;
            color:$blue !important;
        }
    }
}

h4.tn-ticket-selector__pricetype-name {
    font-size:0.9em;
}

h6.tn-ticket-selector__pricetype-name {
    font-size:0.75em;
}

.tn-flex-in-progress-package__section-subhead, .tn-flex-in-progress-package__section--selections, .tn-flex-in-progress-package__special-requests-list {
    display:none;
}


ul.tn-package-list {
    .tn-event-listing-view__no-results-container {
        background-color:#fff;
        padding:1%;
        padding-top:65px;
        min-height: 500px;
        .tn-event-listing-view__no-results-content {
            background-color: #eee;
            border: 1px solid #ddd;
            color: #999;
            padding: 55px 35px;
            font-style: italic;
            text-align: center;
        }
    }
}
