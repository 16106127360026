.tn-promo-box {
    margin-left:0.25em !important;
    margin-right:0 !important;
    #tn-apply-promo {
        .tn-subnav-promo-code {
            border:1px solid rgba(0,0,0,.2);
            padding:5px 10px;
        }
        .tn-subnav-promo-button {
            font-size: 0;
            line-height:36px;
            text-decoration: none;
            &:after {
                background-color:#2e6688;
                color:$white;
                content: ">";
                font-size: 22px;
                padding:5px 15px 8px 15px;
                @media (min-width: $screen-xs-max) {
                    padding:4px 10px 7px 10px;
                }
            }
            &:hover {
                &:after {
                    background-color:#214962;
                }
            }
        }
    }
}

.tn-cart-link {
    text-align:right !important;
}

.tn-timer {
    background: #E44930;
    color: #ffffff;
    display: inline;
    font-weight: bold;
    margin-left:0.25em !important;
    padding: 5px 10px;
}
