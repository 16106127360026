.tn-event-listing-view__results-heading, .tn-prod-list-item__perf-property--datetime {
    color: $gray-dark !important;
}

.tn-prod-list-item__perf-property {
    padding:7px 10px !important;
    width:50% !important;
}

.tn-prod-list-item__perf-property--datetime {
    color:#1D7FBE;
    background-color:#f2f2f2 !important;
    width:100% !important;
    .tn-prod-list-item__perf-date {
        font-weight:bold;
    }
    .tn-prod-list-item__perf-time {
        float:right;
        margin-right:40px;
    }
}

.tn-prod-list-item__perf-property--title {
    p {
        margin-bottom:0;
    }
}

.tn-prod-list-item__perf-property--action {
    padding-right:40px !important;
    text-align:right !important;
}

.tn-radio-button-list {
    .tn-radio-button-list__button {
        background:transparent;
        border:2px solid $blue;
        color:$blue;
        &:hover {
            background:$blue;
            color:$white;
        }
    }
}

.tn-radio-button-list__input:checked+.tn-radio-button-list__button,
.tn-events-keyword-filter__keyword-input:checked+.tn-events-keyword-filter__keyword-button
{
    background-color:$blue !important;
    border-color:$blue !important;
}

.tn-event-listing-mode-tab-nav__list--secondary {
    right:10px !important;
}

.highlight {
    background-color: #E8F7A6;
    display: inline-block;
    padding: 0px 8px;
}

.filtered {
    .tn-event-listing-view__results-container {
        padding-top:65px;
    }
}

.tn-event-listing-view__results-container {
    ul {
        display: flex;
        flex-direction:column;
        order: 3;

        li.tn-prod-list-item {
            @media (max-width: $screen-xs-max) {
                display:flex;
            }

            flex-direction:column;
            order: 1;

            &.general-entry, .tn-prod-list-item__property--heading {
                order: 0;
            }

            .tn-prod-list-item__property--description {
                order: 2;
            }

            &.general-entry-second {
                order: 0;
            }

            .glyphicon {
                @include button-variant(#ffffff, $blue, $blue);
            }

            a {
                text-decoration:none;

                &:hover {
                    color:#214962;
                    text-decoration:underline;
                }
            }
        }
    }
}

.tn-prod-list-item__perf-anchor {
    padding:0 !important;
    &:focus {
        background-color:#E7F8FF;
    }

}

.tn-prod-season-header__title-and-image-container {
    flex-wrap:wrap;
    .tn-prod-season-header__image-wrapper {
        display:inline-block !important;
        margin-bottom:20px;
        width:100%;
    }
}

.tn-prod-list-item__property--img-container {
    display:inline-block !important;
    margin-top:27px !important;
    order: 1;
    width:100%;
    img {
        max-width:100%;
        width:100%;
    }
}

.tn-heading-sub-text {
    height:auto;
    transition: $global-transition;
    transform:scaleY(1);
    &.collapsed {
        display:none;
        transform:scaleY(0);
    }
}

.filtered {
    .tn-events-list-view {
        @media (max-width: $screen-xs-max) {
            margin-top:30px;
        }
    }
}
