.fr {
    .french-lang {
        display:none !important;
    }
}

.en {
    .english-lang {
        display:none !important;
    }
}

.c-header__container {
    display:flex;
    @media (max-width: $screen-lg) {
        position:relative;
    }
    .c-global-nav {
        display:inline-block;
        flex: 1 1 auto;
        margin:10px 0 0 0;
        text-align:right;
        ul {
            list-style:none;
            margin:0 0 5px 0;
            padding:0;
            @media (max-width: $screen-lg) {
                transition: $global-transition;
            }
            li {
                display:inline-block;
                margin:0;
                padding:0;
                @media (max-width: $screen-lg) {
                    width:100%;
                }
                &.logout {
                    margin-top:10px;
                    padding-top:10px;
                    &:before {
                        color:#ffffff;
                        content: "\F007";
                        font-family: FontAwesome;
                    }
                }
                a {
                    color:#6d695c;
                    display:inline-block;
                    padding:5px 20px;
                    text-decoration:none;
                    &:hover {
                        color:#413c2d;
                        text-decoration:underline;
                    }
                    @media (max-width: $screen-lg) {
                        color:$white;
                        &:hover {
                            color:#f2f4cd;
                        }
                    }
                }
            }
        }
        &.c-global-nav--desktop {
            @media (max-width: $screen-lg) {
                display: none;
            }
        }
    }
}

body.state--header-compact {
    .c-global-head__content {
        @media (min-width: $screen-lg) {
            min-height: 100%;
            .c-global-nav {
                padding-top: 20px;
            }
        }
    }
}

.c-global-head__expand {
	transition: $global-transition;
	height: 0;
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	display: block;
	background: #254960;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: 2000;
    body.state--nav-open &  {
        height: 100%;
    }
    @media (min-width: $screen-lg) {
        display: none !important;
	}
    .c-global-nav--expand {
        transition: $global-transition;
        z-index: 20;
        position: relative;
		.c-global-nav__item {
			width: 100%;
			display: inline-block;
			color: $white;
			opacity: 0;
			margin-right: 0;
			margin-bottom: 0.5em;
			a {
                color: $white;
			}
			body.state--nav-open & {
				animation: fadein 200ms ease forwards;
				@for $i from 1 through 20 {
					&:nth-of-type(#{$i}) {
						animation-delay: $i * (30ms) + 30ms;
					}
				}
			}			
		}
	}
}

.c-global-nav-toggle {
    position: absolute;
    right: 15px;
    top:15px;
    cursor: pointer;
    .c-global-nav-toggle__label {
        display: block;
        font-size: 7px;
        margin-bottom: 3px;
        text-align: center;
    }
    @media (min-width: $screen-lg) {
        display: none;
    }
    @media (min-width: $screen-xs-max) and (max-width: $screen-lg) {
        top:33px;
    }
}

ul.lang-mobile {
    border-right:1px solid #333333;
    display: inline-block;
    list-style:none;
    margin:0;
    padding:0 20px 0 0;
    position: absolute;
    right: 60px;
    top: 17px;
    @media (min-width: $screen-xs-max) and (max-width: $screen-lg) {
        top:35px;
    }
    li {
        margin:0;
        a {
            color:#333333;
            font-size:12px;
            text-transform:uppercase;
            &:hover {
                text-decoration:underline;
            }
        }
    }
    @media (min-width: $screen-lg) {
        display: none;
    }  
}

@keyframes fadein{
	0% { opacity:0; }
	100% { opacity:1; }
}
