body.en {
    .fr {
        display: none !important;
    }
}

body.fr {
    .en {
        display: none !important;
    }
}
